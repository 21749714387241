import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, LoadingSpinner } from '@dart-design/core';
import CustomerService from 'services/customer';
import ProductMaterialService from 'services/productMaterial';
import ProductCategoryService from 'services/productCategory';
import MarketChannelService from 'services/marketChannel';
import UserService from 'services/user';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import { withRouter } from 'react-router';
import UserUtils from 'utils/user';

class RequestFilters extends Component {
  static propTypes = {
    selectedFilter: PropTypes.string,
    filterValue: PropTypes.string,
    onSelectedFilterChange: PropTypes.func,
    onFilterValueChange: PropTypes.func
  }

  constructor(props){
    super(props);

    this.state = {
      filters: {},
      selectedFilter: '',
      filterValue: '',
      isLoading: true
    };
  }

  componentDidMount() {
    this.getFilterData();
  }

  componentDidUpdate(prevProps){
    if(prevProps.location.search !== this.props.location.search){
      this.setStateFromQuery();
    }
  }

  getFilterData() {
    let promises = [];

    promises.push(
      CustomerService.get()
        .then(customers => {
          this.addToFilters("Customer", customers.map(c => {
            return {
              value: c,
              label: c
            };
          }));
        })
    );

    promises.push(
      ProductMaterialService.get()
        .then(materials => {
          this.addToFilters("MaterialType", materials.map(m => {
            return {
              value: m.id,
              label: m.name
            };
          }));
        })
    );

    promises.push(
      ProductCategoryService.get()
        .then(categories => {
          this.addToFilters("ProductCategory", categories.map(c => {
            return {
              value: c.id,
              label: c.name
            };
          }));
        })
    );

    promises.push(
      MarketChannelService.get()
        .then(channels => {
          this.addToFilters("MarketChannel", channels.map(c => {
            return {
              value: c.id,
              label: c.name
            };
          }));
        })
    );

    promises.push(
      UserService.getRequesters()
        .then(users => {
          this.addToFilters("Requester", users.map(u => {
            return {
              value: u.id,
              label: UserUtils.displayNameFor(u)
            };
          }));
        })
    );

    promises.push(
      UserService.get()
        .then(users => {
          this.addToFilters("Responder", users.map(u => {
            return {
              value: u.id,
              label: UserUtils.displayNameFor(u)
            };
          }));
        })
    );

    return Promise.all(promises).then(() => {
      this.setState({
        isLoading: false
      });
      this.setStateFromQuery();
    });
  }

  setStateFromQuery() {
    let parsedQuery = parseQuery(this.props.location.search);
    let filters = this.filterNames();
    for(var i = 0; i < filters.length; i++){
      var key = filters[i].value;
      var value = parsedQuery[key];

      if(value) {
        if(!Array.isArray(value)){
          value = [value];
        }

        /* eslint-disable no-loop-func, eqeqeq */
        // no-loop-func: couldn't figure out how to define it outside the loop?
        // and haven't I done this before?
        // eqeqeq: query strings are always strings, need to match up to ints
        // sometimes, and strings other times.
        let filterValue = value.map(v => {
          return this.state.filters[key].find(o => o.value == v);
        });
        /* eslint-enable no-loop-func */

        this.setState({
          selectedFilter: key,
          filterValue: filterValue
        });
      }
    }
  }

  addToFilters(key, value) {
    let currentFilters = this.state.filters;
    currentFilters[key] = value;
    this.setState({filters: currentFilters});
  }

  filterKeyToDisplay(key) {
    return key.replace(/([A-Z])/g, " $1").trim();
  }

  filterNames() {
    return Object.keys(this.state.filters)
      .sort()
      .map(k => {
        return {
          label: this.filterKeyToDisplay(k),
          value: k
        };
      });
  }

  selectFilter(evt) {
    let state = {
      selectedFilter: evt.target.value
    };

    if(this.state.filterValue.length !== 0) {
      state.filterValue = [];
    }

    this.setState(state);

    let parsedQuery = parseQuery(this.props.location.search);
    Object.keys(this.state.filters).forEach(f => parsedQuery[f] = undefined);
    let updatedQuery = stringifyQuery(parsedQuery);

    if(this.props.location.search) {
      this.props.history.push({
        pathname: '',
        search: '?' + updatedQuery
      });
    }
  }

  selectFilterValues(selectedOptions) {
    let parsedQuery = parseQuery(this.props.location.search);
    parsedQuery[this.state.selectedFilter] = selectedOptions.map(v => v.value);
    let updatedQuery = stringifyQuery(parsedQuery);
    this.props.history.push({
      pathname: '',
      search: '?' + updatedQuery
    });
  }

  render() {
    if(this.state.isLoading){
      return <div className="space-stack-md">
        <LoadingSpinner/> Loading Filters
      </div>;
    }

    return <>
      <div className="row request-filter-area">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <Select label="View By"
            onChange={this.selectFilter.bind(this)}
            options={this.filterNames()}
            placeholder="Select one"
            value={this.state.selectedFilter}
            displayKey="label"
            valueKey="value"/>
        </div>
        <div className="col-md-5 col-sm-6 col-xs-12">
          { this.state.selectedFilter ?
            <Select label={this.filterKeyToDisplay(this.state.selectedFilter)}
              options={this.state.filters[this.state.selectedFilter]}
              multiple={true}
              placeholder="Select one or more"
              searchable={true}
              onChange={this.selectFilterValues.bind(this)}
              defaultValue={this.state.filterValue}
              key={this.state.selectedFilter}
              displayKey="label"
              valueKey="value"/>
            : null }
        </div>
      </div>
    </>;
  }
}

export default withRouter(RequestFilters);
